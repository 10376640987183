import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function IndexPage() {
    useEffect(()=> {
        window.location.href = 'https://diralink.com';
        
    }, [])
    return (
        <div>
            Loading
        </div>
    )
}