import { Col, Container, Row } from "react-bootstrap"
import "../style.css";
import { useParams } from "react-router-dom"

export const CaptiveErrorPage = () => {
    const {code} = useParams();


    return (
        <Container className="error-page-container text-center">
            <Row>
                <Col>
                    <h1 className="text-dangerr" >{code || 404} Error</h1>
                    <p>Something bad happened, please try again later</p>
                    {/* <Button onClick={()=> navigate('/')} variant="primary">Go Back</Button> */}
                </Col>
            </Row>
        </Container>
    )
}