export  const timeUnitParser = (unit, duration)=> {
    let timeparser
    switch (unit) {
      case 'minute':
        timeparser = duration > 1 ? `${duration} ${unit}s` : `${duration} ${unit}`
        break;
      case 'hour':
        timeparser = duration > 1 ? `${duration} ${unit}s` : `${duration} ${unit}`
        break;
      case 'day':
        timeparser = duration > 1 ? `${duration} ${unit}s` : `${duration} ${unit}`
        break;
      case 'month':
        timeparser = duration > 1 ? `${duration} ${unit}s` : `${duration} ${unit}`
        break;

      case 'week':
        timeparser = duration > 1 ? `${duration} ${unit}s` : `${duration} ${unit}`
        break;
    
      default:
        break;
    }
    return timeparser

  }

  export const mpesaTimeStamp  = ()=> {
    const today = new Date()

    let month = today.getMonth() + 1 
    let hours = today.getHours()
    let date = today.getDate()
    let mins = today.getMinutes()
    let seconds = today.getSeconds()


    month = month.toString().length === 2  ?month.toString() : 0 + month.toString()
    date = date.toString().length   === 2  ?date.toString() : 0 + date.toString()
    hours = hours.toString().length === 2  ?hours.toString() : 0 + hours.toString()
    mins = mins.toString().length   === 2  ? mins.toString() : 0 + mins.toString()
    seconds = seconds.toString().length === 2  ? seconds.toString() : 0 + seconds.toString()

    const prepareDate = [today.getFullYear(), month, date].join('-')
    const prepareTime = [hours, mins, seconds].join(":")

    return [prepareDate,prepareTime].join(' ')
  }