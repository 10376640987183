import {
  ADD_PACKAGES,
  SELECT_PACKAGE,
  UPDATE_LOADING_STATUS,
  ADD_POTENTIAL_USER,
  CHANGE_FORM_FIELD,
  SET_CURRENT_URL,
  SET_COMPANY_INFO,
} from "./actionTypes"

export const addPackages = (packages) => {
  return {
    type: ADD_PACKAGES,
    payload: packages,
  }
}

export const addPotentialUser = (retries) => {
  return {
    type: ADD_POTENTIAL_USER,
    payload: retries,
  }
}

export const selectPackage = (plan) => {
  return {
    type: SELECT_PACKAGE,
    payload: plan,
  }
}

export const updateIsLoading = () => {
  return {
    type: UPDATE_LOADING_STATUS,
    payload: { status: false },
  }
}

export const changeFormValue = (name, value) => {
  return {
    type: CHANGE_FORM_FIELD,
    payload: { name, value },
  }
}

export const setCurrentUrl = (url) => {
  return {
    type: SET_CURRENT_URL,
    payload: { url},
  }
}

export const setCompanyInfo = (params)=> {
  return {
    type: SET_COMPANY_INFO,
    payload: params
  }
}
