import React, { useEffect, useContext } from "react"
import { Routes, Route } from "react-router-dom"
import { useSearchParams } from "react-router-dom"
import IndexPage from "./default"
import Login from "./components/Login"
import NavBar from "./components/NavBar"
import "./index.css"
import Register from "./components/Register"
import Home from "./pages/Home"
import Renew from "./components/Renew"
import { ErrorPage } from "./pages/Error"
import { CaptiveErrorPage } from "./pages/Error/Captive"
import ConfirmPaymentMpesa from "./components/ConfirmPaymentMpesa"
import Support from "./components/Support/index"
import { getUsercompanyInfo } from "./apis"
import { AppContext } from "./contexts/global.context"
import { setCompanyInfo } from "./contexts/actions"

function App() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { dispatch, company } = useContext(AppContext)

  useEffect(() => {
    if(!company?.info?.id) {
      const companyid = Number(searchParams.get("rs"))
      const fetchData = async () => {
        try {
          const data = await getUsercompanyInfo(companyid)
          const { company: info, contact } = data
          dispatch(setCompanyInfo({ info, contact }))
          console.log(data)
        } catch (error) {
          console.log(error)
        }
      }
      fetchData()

    }
  }, [searchParams, dispatch, company?.info?.id])
  // we need to load data here
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/packages/:id" element={<Home />} />
        <Route path="/login/:id" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/renew" element={<Renew />} />
        <Route path="/confirm/mpesa" element={<ConfirmPaymentMpesa />} />
        <Route path="/error/captive/:code" element={<CaptiveErrorPage />} />
        <Route path="/error/not/found" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Support company={company} />
    </div>
  )
}

export default App
