import React, { useState, useRef, useEffect } from "react"
import { Button, Card, Overlay, Tooltip } from "react-bootstrap"

const Support = ({company}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const target = useRef(null)
  const {info, contact} = company;

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const onclick = () => {
    setShowTooltip(false)
  }

//   useEffect(() => {
//     const handleWindowClick = () => {
//       setShowTooltip(false);
//     };

//     window.addEventListener('click', handleWindowClick);

//     return () => {
//       window.removeEventListener('click', handleWindowClick);
//     };
//   }, []);

  return (
    <div className="floating-button-container">
      <Button ref={target} onClick={toggleTooltip} className="floating-button">
        Support
      </Button>
      <Overlay
        target={target.current}
        show={showTooltip}
        placement="top"
       
      >
        {(props) => (
          <Tooltip    id="tooltip-contained" {...props}  onClick={onclick}>
            <Card  >
              <Card.Header className="text-black">Support Contact</Card.Header>
              <Card.Body>
                <h5 className="text-black">Primary Phone</h5>
                <p className="text-black s-content">{contact?.primary_phone || '0705814794'}</p>
                <h5 className="text-black">Secondary Phone</h5>
                <p className="text-black s-content">{contact?.secondary_phone || '0705814794'}</p>
              </Card.Body>
            </Card>
          </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

export default Support
