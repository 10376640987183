import React from "react"
import { Row, Col, Form, Alert } from "react-bootstrap"
import { Check2Circle } from "react-bootstrap-icons"
import { timeUnitParser } from "../../helpers"

function PaymentInfoForm({ formik, selectedPlan }) {
  const duration = timeUnitParser(
    selectedPlan?.plan?.timeunit,
    selectedPlan?.plan?.duration
  )

  return (
    <>
      <Row className="align-col-center padding-for-bigger-screens">
        <Col>
          <h1 className="text-center page-header my-4">Payment Information</h1>
          <Alert variant="success">
            {`Your have selected ${duration} of unlimited internet for ${"KES"} ${
              selectedPlan?.plan?.price
            } !`}
            <Row>
              <Col>
                <ul className="itemlist">
                  <li>
                    {" "}
                    <Check2Circle color="#0d6efd" size={20} /> Unlimited
                    Internet
                  </li>
                  <li>
                    {" "}
                    <Check2Circle color="#0d6efd" size={20} />{" "}
                    {`${selectedPlan?.category?.bandwidth_max_upload} ${selectedPlan?.category?.speedunit}`}{" "}
                    Upload
                  </li>
                  <li>
                    {" "}
                    <Check2Circle color="#0d6efd" size={20} />{" "}
                    {`${selectedPlan?.category?.bandwidth_max_donwload} ${selectedPlan?.category?.speedunit}`}{" "}
                    Download
                  </li>
                  <li>
                    {" "}
                    <Check2Circle color="#0d6efd" size={20} /> Low Latency
                  </li>
                  <li>
                    {" "}
                    <Check2Circle color="#0d6efd" size={20} /> Hight Throughput
                  </li>
                  <li>
                    {" "}
                    <Check2Circle color="#0d6efd" size={20} /> Hight Bandwidth
                  </li>
                </ul>
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
      <Row className="align-col-center padding-for-bigger-screens">
        <Col>
          <Form.Group className="mb-3" controlId="phoneNumber">
            <Form.Label>Mpesa Phone Number</Form.Label>

            <Form.Control
              type="text"
              placeholder="0xxxxxxxx"
              name="payment.mpesaPhoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.payment.mpesaPhoneNumber}
            />
            {formik.errors.payment && (
              <Form.Text className="text-danger">
                {formik.errors.payment.mpesaPhoneNumber}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default PaymentInfoForm
