import React, { createContext, useReducer } from 'react';
import appReducer, { intialState } from './global.reducer';


export const AppContext = createContext(intialState);

export const AppContextProvider = ({children})=> {
    const [state, dispatch] = useReducer(appReducer, intialState);

    return (
        <AppContext.Provider value={{...state, dispatch}}>
            {children}
        </AppContext.Provider>
    )
}