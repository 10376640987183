import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import  './style.css'

 function NavBar() {

    return (
        <Navbar bg="primary" variant="dark" expand="lg" className="nav-bar-shadow mb-3">
            <Container>
                <Navbar.Brand color='light'>DiraLink</Navbar.Brand>
            </Container>
        </Navbar>
    )

}
export default NavBar