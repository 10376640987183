import React from "react"
import { Col, Row } from "react-bootstrap"
import Plan from '../Plan'

function PlanCategory({plan}) {

    const packages = (plan?.packages || []).map((item)=> (
      <Col index={item.id} md={3} >
          <Plan plan={{
            ...item, 
            uploadSpeed: plan.bandwidth_max_upload, 
            donwloadSpeed: plan.bandwidth_max_donwload,
            speedunit: plan.speedunit}}/>
      </Col>))

  return (<>
  <Row className="">
    <Col>
      <h1 className="text-center page-header">Unlimited Internet</h1>
      <h5 className="text-center page-subheader">Select your plan and we will get you step up in minutes. No hidden charges, choose your plan.</h5>
    </Col>
  </Row>
  <Row className="mt-3">
    {plan && plan?.packages.length> 0 ? packages: <Col><p>no package</p></Col>  }
  </Row>


  </>)
}

export default PlanCategory;
