import React, { useState, useEffect,useContext } from "react"
import { Row, Col, Alert, Card, Button, Toast, ToastContainer } from "react-bootstrap"
import { useNavigate ,useSearchParams } from "react-router-dom"
import { queryMpesaPayment } from "../../apis";
import { AppContext } from "../../contexts/global.context";

function ConfirmPaymentMpesa() {
    const navigate = useNavigate()
  const [paymentStatus, setPaymentStatus] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [btnMessage, setBTNMessage] = useState('Confirm Payment');
  const [btnStatus, setBtnStatus] = useState(false)
  const {currentUrl: previousUrl} = useContext(AppContext);
  const [showToast, setToast] = useState(false);
  const [error, setError] = useState(null);




//   useEffect(()=> {
//     if(!reloadedBySystem) {
//         const handleBeforeUnload = (ev) =>  {
//             ev.preventDefault()
//             ev.returnValue = 'Are you sure you want to exit this Page'
//           }
//           window.addEventListener('beforeunload',handleBeforeUnload)
      
//           return () => {
//             window.removeEventListener('beforeunload',handleBeforeUnload)
//           }
//     }
//   }, [reloadedBySystem])



  const pendingAlert = (
    <Alert variant="warning">
      <h6>
        After Making the payment on MPESA, please click on the <b>Confirm Payment</b>{" "}
        button below to complete your payment
      </h6>
    </Alert>
  )

  const confirmedAlert = (
    <Alert variant="success">
      <h6>Your purchase was successfull</h6>
    </Alert>
  )

  const handleOnClick = async ()=> {
    const invoice = Number(searchParams.get('invoice'))
    const user = Number(searchParams.get('user'))
    try {
        setBTNMessage('Processing...');
        setBtnStatus(true);
        const response = await queryMpesaPayment(invoice);
        setPaymentStatus(response);
        if(response.paid) {
            setBTNMessage('Payment Confirmed');
            navigate(`/login/${user}`, {replace:true});
        }else {
             const seach1 = previousUrl.search(/user/);
            const search2 = previousUrl.search(/retry/);
            if(seach1 !== -1 && search2 !== -1) {
              window.location.replace(`${previousUrl}`);
            }else {
              window.location.replace(`${previousUrl}&user=${user}&retry=${1}`);
            }


        }
    } catch (error) {
        // show toaster about the error
        setBTNMessage('Confirm Payment');
        setBtnStatus(false);
        setError(error)
        setToast(true)
    }
  }

  const pendingBtn = (
    <Button disabled={btnStatus} onClick={handleOnClick} variant="primary" size="lg">
      {btnMessage}
    </Button>
  )

  const processingBtn = (
    <Button disabled={true} variant="secondary" size="lg">
      {btnMessage}
    </Button>
  )

  const message = paymentStatus.paid ? 'Payment Completed' : 'Payment Pending'

  return (
    <>
    <Row className="align-col-center padding-for-bigger-screens">
      <Col md={4} xs={12}>
        <Card className="py-3">
          <Card.Body>
            <h1 className="text-center page-header my-4">{message}</h1>
            {paymentStatus.paid ? confirmedAlert : pendingAlert}
            <div className="d-grid gap-2">
              {paymentStatus.paid ? processingBtn : pendingBtn}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    {/* toast */}
    <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setToast(false)} show={showToast} delay={3000} autohide >
          <Toast.Header onClick={()=> () => setToast(false)} closeButton= {false}>
            <strong  className="me-auto text-warning">In Progress</strong>
          </Toast.Header >
          <Toast.Body className="text-success">{error?.response?.data?.message || "please wait"}</Toast.Body>
        </Toast>
        </ToastContainer>
    </>
  )
}

export default ConfirmPaymentMpesa
