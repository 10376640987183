import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.timeout = 5000 // 5 seconds

export const getPotential = async (potentialId) => {
  try {
    const response = await axios.get(`/captive/portal/new/${potentialId}`)
    return response.data
  } catch (error) {
    // we need to know which error . chec error.data
    throw error
  }
}

export const getPackages = async (companyid, hotspotid) => {
  try {
    const response = await axios.get(`/radius/customers/packages/${companyid}?hotspotid=${hotspotid}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getPlan = async (packageid) => {
  try {
    const response = await axios.get(`/radius/customers/package/${packageid}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getPackage_potential = async (potentialId, packageid) => {
  try {
    const [potential, plan] = await Promise.all([
      getPotential(potentialId),
      getPlan(packageid),
    ])
    return [potential, plan]
  } catch (error) {
    throw error
  }
}

/**
 * 
 {
      customer: {
        username: personalInfo.username,
        password: personalInfo.password,
        email: personalInfo.email,
        phone: personalInfo.phone,
      },
      device: {
        mac: retries.mac,
        ip_address: retries.ip,
        companyid: retries.companyid
      },
    }
 * @returns 
 */
export const saveCustomer = async (userData) => {
  try {
    const response = await axios.post("/radius/customers", userData)
    return response.data
  } catch (error) {
    // we need to know which error . chec error.data
    throw error
  }
}

/**
 {
    "customerid": 48,
    "companyid": 1,
    "amount": 1.00,
    "msidn": "0705814794",
    "channel": "web",
    "timestamp": "2023-05-21 11:44:40",
    "packageid": 34,
    "device":"01:23:45:67:89:AB"
    "transferType": "onlineMpesaC2B"
}
 */
export const initiateMpesaStkPayment = async (paymentData) => {
  try {
    const response = await axios.post("/radius/customer/pay", paymentData, {
      timeout: 10000,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

/**
 * this is going to check payment status if paid = true
 {
  "invoiceid": 30
}
 */
export const checkPaymentInternal = async (invoiceid) => {
  try {
    const response = await axios.post("/transfer/query/internal", {
      invoiceid: invoiceid,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const queryMpesaPayment = async (invoiceid) => {
  try {
    const response = await axios.post("/radius/customer/pay/status", {
      invoiceid: invoiceid,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

/**
 * this is going to check payment from the merchant
 {
  "invoiceid": 30
}
 */
export const checkPaymentInMpesaSystem = async (invoiceid) => {
  try {
    const response = await axios.post("/transfer/mpesa/query/stkpush", {
      invoiceid: invoiceid,
    })
    return response.data
  } catch (error) {
    throw error
  }
}


export const getUserById = async (id) => {
  try {
    const response = await axios.get(`/radius/customers/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getUsercompanyInfo = async (id) => {
  try {
    const response = await axios.get(`/radius/customers/company/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const deleteEntry = async (id) => {
  try {
     await axios.post("/captive/remove/entry", {
      id,
    })
  } catch (error) {
    throw error
  }
}

export const findEntryByMac = async (mac) => {
  try {
     const response = await axios.post("/captive/get/mac", {
      mac,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getCustomerbyMac = async (mac) => {
  try {
    const response = await axios.post("radius/customer/search", {
     mac,
   })
   return response.data
 } catch (error) {
   throw error
 }
}