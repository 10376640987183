import React, { useContext } from "react"
import { AppContext } from "../../contexts/global.context"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Card, Button, Row, Container, Col } from "react-bootstrap"
import { Check2Circle } from "react-bootstrap-icons"
import { timeUnitParser } from "../../helpers"
import { selectPackage } from "../../contexts/actions"

function Plan({ plan }) {
  let navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { dispatch, postentialUser } = useContext(AppContext)
  const statusid = searchParams.get('status')

  const selectAndUpdate = () => {
    dispatch(selectPackage(plan))
    if(Number(statusid) === 1) {
      navigate(`/register?status=${statusid}&potential=${postentialUser.id}&plan=${plan.id}&rs=${postentialUser.companyid}`, { replace: true })
    }else if (Number(statusid) === 2) {
      navigate(`/renew?status=${statusid}&potential=${postentialUser.id}&plan=${plan.id}&rs=${postentialUser.companyid}`, { replace: true })
    }
  }
  return (
    <Container>
      <Row>
        <Col>
          <Card className="mb-3">
            <Card.Header className="">
              {timeUnitParser(plan?.timeunit, plan?.duration)}
            </Card.Header>
            <Card.Body>
              <Card.Title> {`${plan?.currency} ${plan?.price}`} </Card.Title>
              <ul className="itemlist">
                <li>
                  {" "}
                  <Check2Circle color="#0d6efd" size={20} /> Unlimited Internet
                </li>
                <li>
                  {" "}
                  <Check2Circle color="#0d6efd" size={20} />{" "}
                  {`${plan?.uploadSpeed} ${plan?.speedunit}`} Upload
                </li>
                <li>
                  {" "}
                  <Check2Circle color="#0d6efd" size={20} />{" "}
                  {`${plan?.donwloadSpeed} ${plan?.speedunit}`} Download
                </li>
                <li>
                  {" "}
                  <Check2Circle color="#0d6efd" size={20} /> Low Latency
                </li>
                <li>
                  {" "}
                  <Check2Circle color="#0d6efd" size={20} /> Hight Throughput
                </li>
                <li>
                  {" "}
                  <Check2Circle color="#0d6efd" size={20} /> Hight Bandwidth
                </li>
              </ul>
              <Button onClick={selectAndUpdate} variant="primary">
                Subscribe
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Plan
