import React, { useState, useEffect, useContext } from "react"
import { BoxLoading } from "react-loadingg"
import { useNavigate ,useSearchParams } from "react-router-dom"
import {
  Card,
  Col,
  Container,
  Row,
  Modal,
  Spinner,
  Button,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import PaymentInfo from "../PaymentInfo"
import {
  checkPaymentInMpesaSystem,
  checkPaymentInternal,
  getPackage_potential,
  initiateMpesaStkPayment,
  saveCustomer,
} from "../../apis"
import { mpesaTimeStamp } from "../../helpers"
import { AppContext } from "../../contexts/global.context";
import { setCurrentUrl } from "../../contexts/actions";

function Register() {
  const [show, setShow] = useState(false)
  const [showToast, setToast] = useState(false)
  const [serverError, setServerError] = useState(null)
  
  const [buttonMessage, setButtonMessage] = useState("Pay")
  const [disableButton, setDisableButton] = useState(false)
  
  const [isLoading, setIsLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [ selectedPlan, setselectedPlan ] = useState(null)
  const [postentialUser, setPotentialUser] = useState(null)
  const [triedBefore, setTriedBefore] = useState(Number(searchParams.get('retry')) || 2)

  const navigate = useNavigate()
  const {dispatch} = useContext(AppContext);

  useEffect(()=> {
    if(triedBefore ===1) {
      setButtonMessage('Retry Again')
    }
  }, [triedBefore])

  useEffect(()=> {
    const fetchData = async () => {
      try {
        const [potential, plan] = await getPackage_potential(searchParams.get('potential'), searchParams.get('plan') )
        setPotentialUser(potential)
        setselectedPlan(plan)
      } catch (error) {
        throw error
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [searchParams ,setselectedPlan])



  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = async (values) => {
    // prepare data to send to backend
    const { payment } = values

    const userDevice = {
      customer: {
        username: postentialUser.mac, //! this will be replaced in future phase 2
        email: postentialUser.mac, //! will be replaced in future phase 2
        phone: payment.mpesaPhoneNumber,
      },
      device: {
        mac: postentialUser.mac,
        ip_address: postentialUser.ip,
        companyid: postentialUser.companyid,
      },
    }
    // todo. save user data
    try {
    
      setButtonMessage("Saving Customer...")
      setDisableButton(true)
      const savedUser = triedBefore === 1 ? {id: Number(searchParams.get('user'))}:  await saveCustomer(userDevice)

      if (savedUser && savedUser.id) {
        setButtonMessage("Processing Payment")

        const paymentPayload = {
          customerid: savedUser.id,
          companyid: postentialUser.companyid,
          amount: Number(selectedPlan.plan.price),
          msidn: payment.mpesaPhoneNumber,
          channel: "web",
          timestamp: mpesaTimeStamp(),
          packageid: selectedPlan.plan.id,
          device: postentialUser.mac,
          hotspot: +postentialUser.server_name,
          transferType: 'onlineMpesaC2B'
        }
        // initiate mpesa payment
        const response = await initiateMpesaStkPayment(paymentPayload)
        if (response && response.requstsent) {
          // redirect to payment, user has received stk push
          dispatch(setCurrentUrl(window.location.href))
          navigate(`/confirm/mpesa?user=${postentialUser.id}&invoice=${response.invoiceid}&rs=${postentialUser.companyid}`, { replace: true })
          // handleShow() // show this after make payment sent
          // var k = 0
          // const checkDB = setInterval(async () => {
          //   console.log("repeating")
          //   const checkInternal = await checkPaymentInternal(response.invoiceid)
          //   if (checkInternal && checkInternal.paid) {
          //     console.log("not entered atll all", checkInternal)
          //     handleClose()
          //     clearInterval(checkDB)
          //     // redirect user
          //     navigate(`/login/${postentialUser.id}`)
          //   }
          //   //if axios successful, redirect use to login after which they will login
          //   k++
          //   console.log(k)
          //   console.log("end of repeating")
          //   if (k === 15) {
          //     // check mpesa system
          //     const checkExternal = await checkPaymentInMpesaSystem(
          //       response.invoiceid
          //     )
          //     if (checkExternal && checkExternal.paid) {
          //       handleClose()
          //       clearInterval(checkDB)
          //       // save in local storage
          //       // redirect user
          //       navigate(`/login/${postentialUser.id}`)
          //     } else {
          //       //  user did not pay
          //       handleClose()
          //       clearInterval(checkDB)
          //       setButtonMessage("Retry")
          //       setDisableButton(false)
          //       setServerError(checkExternal)
          //       setToast(true)
          //     }
          //   }
          // }, 3000)
          // end
        }
      }
    } catch (error) {
      if (error.response) {
        setButtonMessage("Retry")
        setDisableButton(false)
        setServerError(error.response.data)
        setToast(true)
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <Container><BoxLoading color="#0d6efd" /></Container>
      ) : (
        <Container>
          <Row className="align-col-center">
            <Col md={8}>
              <Card className="py-3">
                <Card.Body>
                  <Form onSubmit={formik.handleSubmit}>
                    <PaymentInfo formik={formik} selectedPlan={selectedPlan} />
                    <div className="space-between-elements">
                      <Button
                        disabled={disableButton}
                        variant="primary"
                        type="submit"
                      >
                        {buttonMessage}
                      </Button>
                      <Button
                        disabled={disableButton}
                        onClick={() =>
                          navigate(`/packages/${postentialUser?.id}?status=${searchParams.get('status')}&rs=${postentialUser.companyid}`, {
                            replace: true,
                          })
                        }
                        className="ml-3"
                        variant="danger"
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* modal */}
          <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Body className="align-col-center">
              <div className="d-flex space-between-elements">
                <Spinner
                  animation="border"
                  variant="primary"
                  className="pr-2"
                ></Spinner>
                <h6 className="ml-3">Processing payment...</h6>
              </div>
            </Modal.Body>
          </Modal>

          {/* toast */}
          <ToastContainer position="top-end" className="p-3">
            <Toast
              onClose={() => setToast(false)}
              show={showToast}
              delay={3000}
              autohide
            >
              <Toast.Header
                onClick={() => () => setToast(false)}
                closeButton={false}
              >
                <strong className="me-auto text-warning">
                  Error Code {serverError?.code}
                </strong>
              </Toast.Header>
              <Toast.Body className="text-danger">
                {serverError?.message}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Container>
      )}
    </>
  )
}

// initial values
const initialValues = {
  payment: {
    mpesaPhoneNumber: "",
  },
}

// validation schema
const validationSchema = Yup.object({
  payment: Yup.object({
    mpesaPhoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "invalid phone")
      .required("This field is required")
      .length(10, "invalid phone  format"),
  }),
})

export default Register
