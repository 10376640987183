import React, { useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom";
import { BoxLoading } from 'react-loadingg';


import PlanCategory from '../../components/PlanCategory';

import Container from "react-bootstrap/Container"
import { AppContext } from "../../contexts/global.context";
import { addPackages } from "../../contexts/actions";
import { addPotentialUser, updateIsLoading } from "../../contexts/actions";
import { getPackages, getPotential } from "../../apis";




function Home() {
  const navigate = useNavigate()
    const {id} = useParams();
    const {dispatch, packages, isLoading} = useContext(AppContext)


    /**
     * 
     * 
     */
    useEffect(()=> {
      let isFetched = true
      const fetchData = async()=> {
        const potentialUser = await getPotential(id);
        const packages = await getPackages(potentialUser.companyid, potentialUser.server_name);
        if(isFetched){
          dispatch(addPotentialUser(potentialUser))
         const defaultPackage =  (packages|| []).find(element=> element.groupname === 'default');
         dispatch(addPackages(defaultPackage))
         dispatch(updateIsLoading())
        }

      }
      fetchData()
      .catch(error=> {
        if(error.response.data) {
          navigate('/error/not/found') // we need to show contact here
        }
      })

      return ()=> isFetched=false
    }, [id])

  return (
    <Container>
       {isLoading ? <BoxLoading color="#0d6efd" />: <PlanCategory plan={packages || []} />}
    </Container>
  )
}

export default Home
