import { Col, Container, Row ,Button } from "react-bootstrap"
import "./style.css";
import { useNavigate } from "react-router-dom"

export const ErrorPage = () => {
    const navigate = useNavigate()


    return (
        <Container className="error-page-container text-center">
            <Row>
                <Col>
                    <h1>404 Error</h1>
                    <p>Something bad happened, please try again later</p>
                    {/* <Button onClick={()=> navigate('/')} variant="primary">Go Back</Button> */}
                </Col>
            </Row>
        </Container>
    )
}