import { ADD_PACKAGES, ADD_POTENTIAL_USER, UPDATE_LOADING_STATUS, SELECT_PACKAGE, CHANGE_FORM_FIELD, SET_CURRENT_URL, SET_COMPANY_INFO } from "./actionTypes";

export const intialState ={
    currentUrl: 'https://diralink.com',
    packages: [],
    postentialUser: {},
    isLoading: true,
    selectedPlan: {},
    create:{
        
    },
    edit:{},
    company: {
        info: {},
        contact: {}
    }
}

function appReducer(state = intialState, action){
    switch (action.type) {
        case ADD_PACKAGES:{
            return {
                ...state,
                packages: action.payload
            }
        }
        case ADD_POTENTIAL_USER:{
            return {
                ...state,
                postentialUser: action.payload
            }
        }
        case UPDATE_LOADING_STATUS:{
            return {
                ...state,
                isLoading: action.status //! change this
            }
        }
        case SELECT_PACKAGE:{
            return {
                ...state,
                selectedPlan: action.payload
            }
        }
        case CHANGE_FORM_FIELD:{
            const {name, value} = action.payload
            const newInfo = state.create
            return {
                ...state,
                create: {...newInfo, [name]: value}
            }
        }
        case SET_CURRENT_URL:{
            const {url} = action.payload;
            return {
                ...state,
                currentUrl: url
            }
        }
        case SET_COMPANY_INFO:{
            const {info, contact} = action.payload;
            return {
                ...state,
                company: {
                    contact:contact,
                    info: info
                }
            }
        }
        default: {
            return state
        }
    }

}


export default appReducer